import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// tslint:disable-next-line: max-line-length
import {
   HttpService,
   internalErrorHandler,
   isResultValid,
   Redirect,
   reloadHandler,
   timeoutHandler,
   translate,
   TranslateHandler,
   TranslatePipe,
   unauthorizedHandler,
} from '@szegedsw/http.service';
import { Locales, ModalHandler, translateModal } from '@szegedsw/modal';
import { translateButtons, translateDropdown } from '@szegedsw/navbar';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { version } from '../../package.json';
import { api } from './config/config';
import { Event } from './entities/event.entity';
import { IPostSession, ISession } from './entities/session.entity';
import { IRights } from './entities/user.entity';
import { navCallbook, navHandler, navLogin as navLoggedIn, navLogout, setNavButton, site } from './nav/nav.handler';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
   static spinner: NgxSpinnerService;
   private static events: Event[] = undefined;
   static addModal: ModalHandler = new ModalHandler();
   static deleteModal: ModalHandler = new ModalHandler();

   static loginEvent: Subject<IPostSession> = new Subject<IPostSession>();
   private static loginObservable = AppComponent.loginEvent.asObservable();
   static logoutEvent: Subject<void> = new Subject<void>();
   private static logoutObservable = AppComponent.logoutEvent.asObservable();
   static languageEvent: Subject<Locales> = new Subject<Locales>();
   public static languageObservable = AppComponent.languageEvent.asObservable();
   static syncEvent: Subject<void> = new Subject<void>();
   static syncObservable = AppComponent.syncEvent.asObservable();
   public static session: ISession;

   static timeoutHandler: ModalHandler;
   get timeoutHandler(): ModalHandler {
      return AppComponent.timeoutHandler;
   }
   static internalErrorHandler: ModalHandler;
   get internalErrorHandler(): ModalHandler {
      return AppComponent.internalErrorHandler;
   }
   static unauthorizedHandler: ModalHandler;
   get unauthorizedHandler(): ModalHandler {
      return AppComponent.unauthorizedHandler;
   }
   static errorHandler: ModalHandler = new ModalHandler();
   get errorHandler(): ModalHandler {
      return AppComponent.errorHandler;
   }
   static reloadHandler: ModalHandler = new ModalHandler();
   get reloadHandler(): ModalHandler {
      return AppComponent.reloadHandler;
   }

   routerSubscription: Subscription;
   queryParamMapSubscription: Subscription;
   loginSubscription: Subscription;
   changePasswordSubscription: Subscription;
   logoutSubscription: Subscription;
   translateSubscription: Subscription;
   languageSubscription: Subscription;

   sessionService: HttpService;
   translateService: HttpService;
   navbar = true;
   footer = true;
   year = new Date().getFullYear();
   redirect: Redirect;
   // tslint:disable-next-line: member-ordering
   public static redirect: Redirect;
   version = version;
   isDefaultSite = true;

   get addModal(): ModalHandler {
      return AppComponent.addModal;
   }
   get deleteModal(): ModalHandler {
      return AppComponent.deleteModal;
   }

   static async getEvents() {
      if (AppComponent.events) {
         return AppComponent.events;
      } else {
         return await new Promise<Event[]>((resolve) =>
            setTimeout(async () => {
               resolve(await AppComponent.getEvents());
            }, 20),
         );
      }
   }
   static setEvents(events: Event[]) {
      AppComponent.events = events;
   }

   static isRight(right: keyof IRights): boolean {
      return AppComponent.session && AppComponent.session.rights && AppComponent.session.rights.includes(right);
   }

   static isEventRight(eventId: string): boolean {
      return AppComponent.session.events.findIndex((event) => String(event) === eventId) >= 0;
   }

   static async onUnauthorized(session: ISession) {
      AppComponent.session = session;
      navLogout();
      if (AppComponent.session.userId) {
         await navLoggedIn();
      }
      AppComponent.unauthorizedHandler.event.next();
   }

   onTimeout() {
      navLogout();
      this.redirect.toDefaultUrl();
   }

   onUnauthorized() {
      this.redirect.toDefaultUrl(undefined, false);
   }

   translateNavbar = () => {
      navHandler.navs.forEach((nav) => translateDropdown(nav, translate));
      translateButtons(navHandler.buttons, translate);
   };

   constructor(
      private router: Router,
      private spinner: NgxSpinnerService,
      private http: HttpClient,
      private route: ActivatedRoute,
      private cookieService: NgcCookieConsentService,
      private toastr: ToastrService,
   ) {
      this.redirect = new Redirect(this.router, this.route);
      AppComponent.redirect = this.redirect;

      TranslateHandler.construct(['en', 'hu']);

      AppComponent.addModal.localTime = false;
      AppComponent.addModal.ignoreBackdropClick = true;
      AppComponent.addModal.classes = ['modal-dialog-centered'];

      AppComponent.deleteModal.localTime = false;
      AppComponent.deleteModal.ignoreBackdropClick = true;
      AppComponent.deleteModal.classes = ['modal-dialog-centered'];

      AppComponent.internalErrorHandler = internalErrorHandler();
      AppComponent.timeoutHandler = timeoutHandler();
      AppComponent.unauthorizedHandler = unauthorizedHandler();
      AppComponent.reloadHandler = reloadHandler();

      this.routerSubscription = this.router.events.subscribe((event) => {
         if (event instanceof NavigationEnd) {
            this.redirect.setUrlAfterRedirects(event.urlAfterRedirects);
         }
      });

      this.queryParamMapSubscription = this.route.queryParamMap.subscribe((queryParamMap) => {
         this.navbar = queryParamMap.get('navbar') !== 'hide';
         this.footer = queryParamMap.get('footer') !== 'hide';
         TranslateHandler.queryParamMap(queryParamMap);
      });

      AppComponent.spinner = this.spinner;

      this.sessionService = new HttpService(this.http, api('nest', 'session'));
      this.translateService = new HttpService(this.http, api('nest', 'translation'));

      this.loginSubscription = AppComponent.loginObservable.subscribe((credentials) => {
         this.sessionService.post<ISession>(credentials, async (res) => {
            if (isResultValid(res)) {
               this.toastr.success(translate('You logged in successfully!'), translate('Login successful!'), {
                  positionClass: 'toast-bottom-right',
               });
               AppComponent.session = res.body;
               this.translateSession(res);
               setTimeout(() => {
                  this.router.navigate(['/eval/chooser']);
               }, 500);
            }
         });
      });

      this.logoutSubscription = AppComponent.logoutObservable.subscribe(() => {
         navLogout();
         this.redirect.toDefaultUrl();
         AppComponent.session.userId = '';
         this.redirect.setUrlAfterRedirects(undefined);
         this.sessionService.delete<ISession>('', {}, (res) => {
            if (isResultValid(res)) {
               AppComponent.session = undefined;
            }
         });
      });

      this.translateSubscription = TranslatePipe.changed.subscribe(() => {
         this.translateNavbar();
         AppComponent.timeoutHandler.change.next(translateModal(AppComponent.timeoutHandler, translate));
         AppComponent.internalErrorHandler.change.next(translateModal(AppComponent.internalErrorHandler, translate));
         AppComponent.reloadHandler.change.next(translateModal(AppComponent.reloadHandler, translate));
         AppComponent.unauthorizedHandler.change.next(translateModal(AppComponent.unauthorizedHandler, translate));
      });

      this.languageSubscription = AppComponent.languageObservable.subscribe((locale) => {
         TranslatePipe.setLocale(locale);
         TranslateHandler.setLockedLocale();
         TranslateHandler.startSession(AppComponent, this, AppComponent.session.userId ? undefined : true, async () => undefined);
      });
   }

   ngOnInit(): void {
      setTimeout(() => {
         this.sessionService.get<ISession>({}, (res) => {
            AppComponent.session = res.body;
            this.isDefaultSite = res.body.defaultSites.includes(site);
            if (this.isDefaultSite) {
               setNavButton(!!res.body.userId);
               navCallbook();
            }
            this.translateSession(res);
         });
      }, 500);
      if (!this.cookieService.hasConsented()) {
         this.cookieService.destroy();
         this.cookieService.init(this.cookieService.getConfig());
         this.cookieService.open();
      }
   }

   ngOnDestroy(): void {
      this.routerSubscription.unsubscribe();
      this.queryParamMapSubscription.unsubscribe();
      this.loginSubscription.unsubscribe();
      this.changePasswordSubscription.unsubscribe();
      this.logoutSubscription.unsubscribe();
      this.translateSubscription.unsubscribe();
      this.languageSubscription.unsubscribe();
   }

   translateSession(res: HttpResponse<ISession>) {
      TranslateHandler.startSession(AppComponent, this, AppComponent.session.userId ? undefined : true, async () => {
         if (isResultValid(res) && AppComponent.session.userId) {
            await navLoggedIn();
            this.translateNavbar();
            await this.redirect.toReturnUrl();
         }
      });
   }

   onLogin() {
      this.router.navigateByUrl('login');
      // AppComponent.navLoginEvent.next();
   }
}
