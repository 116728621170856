import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService } from '@szegedsw/http.service';
import { Codes, Headers } from '@szegedsw/table';
import { preliminaryQsosConstructor, preliminaryQsosOnInit } from 'src/app/common/preliminary-qsos.common';
import { api } from 'src/app/config/config';
import { Qso } from 'src/app/entities/qso.entity';

@Component({
   selector: 'app-preliminary-qsos',
   templateUrl: './preliminary-qsos.component.html',
   styleUrls: ['./preliminary-qsos.component.css'],
})
export class PreliminaryQsosComponent implements OnInit {
   rows: Qso[] = [];

   headers: Headers;
   codes: Codes;

   details = [];
   detailHeaders: Headers;
   detailCodes: Codes;

   service: HttpService;
   params: Map<string, string> = new Map();
   queryParams: Params = {};

   constructor(private http: HttpClient, public route: ActivatedRoute, public router: Router) {
      this.service = new HttpService(this.http, api('nest', 'qso'));
      preliminaryQsosConstructor(this);
   }

   ngOnInit() {
      preliminaryQsosOnInit(this);
   }

   onHeaderEvent(event: number) {
      if (event === 0) {
         this.router.navigate(['/results', this.params.get('event'), 'logs', 'preliminary'], { queryParams: this.queryParams });
      } else {
         this.router.navigateByUrl('results/error-codes');
      }
   }
}
