export enum Band {
   unknown = 'UNK',
   all = 'ALL',
   '160m' = '160M',
   '80m' = '80M',
   '40m' = '40M',
   '20m' = '20M',
   '15m' = '15M',
   '10m' = '10M',
   '6m' = '6M',
   '4m' = '4M',
   '2m' = '2M',
   '222M' = '222',
   '432M' = '432',
   '902M' = '902',
   '1G2' = '1G2',
   '2G3' = '2G3',
   '3G4' = '3G4',
   '5G7' = '5G7',
   '10G' = '10G',
   '24G' = '24G',
   '47G' = '47G',
   '75G' = '75G',
   '123G' = '123G',
   '134G' = '134G',
   '241G' = '241G',
   light = 'Light',
   outOfBand = 'OUT-OF-BAND',
}

export enum Mode {
   cw = 'CW',
   phone = 'PH',
   fm = 'FM',
   rtty = 'RY',
   digi = 'DG',
   am = 'AM',
   sstv = 'SSTV',
   atv = 'ATV',
   unknown = 'UNK',
}
