import { Pipe, PipeTransform } from '@angular/core';
import { AppComponent } from '../app.component';

@Pipe({
   name: 'eventName',
})
export class EventNamePipe implements PipeTransform {
   async transform(value: any): Promise<any> {
      return (await AppComponent.getEvents()).find((event) => event._id === value).name;
   }
}
