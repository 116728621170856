/* eslint-disable no-shadow */
import { IconClass } from '@szegedsw/icon';
import { DXCC } from './dxcc.entity';
import { Event } from './event.entity';
import { Log } from './log.entity';

export enum Qsos {
   _id = '_id',
   log = 'log',
   callsign = 'callsign',
   dateTime = 'dateTime',
   mode = 'mode',
   sRst = 'sRst',
   sNum = 'sNum',
   sExch = 'sExch',
   rRst = 'rRst',
   rNum = 'rNum',
   rExch = 'rExch',
   rWWL = 'rWWL',
   qsoPoints = 'qsoPoints',
   dupe = 'dupe',
   multiplier = 'multiplier',
   multis = 'multis',
   status = 'status',
   statusRsn = 'statusRsn',
   comment = 'comment',
   privateComment = 'privateComment',
   freq = 'freq',
   band = 'band',
   manual = 'manual',
   event = 'event',
   dxcc = 'dxcc',
   logStatus = 'logStatus',
   createdAt = 'createdAt',
   updatedAt = 'updatedAt',
}

export class Qso {
   public index!: string;

   public [Qsos.log]!: Log;

   public [Qsos.callsign]!: string;

   public [Qsos.dateTime]!: Date;

   public [Qsos.mode]!: string;

   public [Qsos.sRst]?: number;

   public [Qsos.sNum]?: number;

   public [Qsos.sExch]?: string;

   public [Qsos.rRst]?: number;

   public [Qsos.rNum]?: number;

   public [Qsos.rExch]?: string;

   public [Qsos.rWWL]?: string;

   public [Qsos.qsoPoints]?: number;

   public [Qsos.dupe]?: boolean;

   public [Qsos.multiplier]?: boolean;

   public [Qsos.multis]?: string[];

   public [Qsos.status]!: string;

   public [Qsos.statusRsn]?: string;

   public [Qsos.comment]?: string;

   public [Qsos.privateComment]?: string;

   public [Qsos.freq]?: number;

   public [Qsos.band]?: string;

   public [Qsos.manual]?: boolean;

   public [Qsos.event]?: Event;

   public [Qsos.dxcc]?: DXCC;

   public [Qsos.logStatus]?: string;

   public [Qsos._id]!: string;

   public [Qsos.createdAt]!: Date;

   public [Qsos.updatedAt]!: Date;

   validation?: any[] | IconClass;

   warning?: boolean;

   danger?: boolean;
}
