import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Qso } from '../../qso-typer/qso-typer.component';

@Component({
   selector: 'app-qso-card',
   templateUrl: './qso-card.component.html',
   styleUrls: ['./qso-card.component.css'],
})
export class QsoCardComponent {
   @Input('qsos') qsos: Qso[];
   @Input('isFreq') isFreq: boolean;
   @Input('isOffAirContest') isOffAirContest: boolean;
   @Input('dupePeriod') dupePeriod: boolean;
   @Input('isXQso') isXQso: boolean;
   @Output('xConfirm') xConfirm = new EventEmitter<{ data: { row: Qso }; isX: boolean }>();
   @Output('iconClick') iconClick = new EventEmitter<{ id: string; row: Qso }>();
   @Output('finish') finish = new EventEmitter<any>();

   onXConfirm(data: { row: Qso }, isX: boolean) {
      this.xConfirm.emit({ data, isX });
   }

   onIconClick(id: string, row: Qso) {
      this.iconClick.emit({ id, row });
   }

   onFinish() {
      this.finish.emit();
   }
}
