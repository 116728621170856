import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from '@szegedsw/alert';
import { IconModule } from '@szegedsw/icon';
import { LocaleModule, ModalModule } from '@szegedsw/modal';
import { NavbarModule } from '@szegedsw/navbar';
import { SelectModule } from '@szegedsw/select';
import { TableModule } from '@szegedsw/table';
import { defineLocale, enGbLocale, huLocale, plLocale, roLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { EventNamePipe } from '../services/event-name.pipe';
import { TranslatePipe } from '../services/translate.pipe';
import { SubmitFormComponent } from '../submit-form/submit-form.component';
import { SubmitComponent } from '../submit/submit.component';
import { ResultFilterComponent } from './result-filter/result-filter.component';

defineLocale('hu', huLocale);
defineLocale('hu-hu', huLocale);
defineLocale('en', enGbLocale);
defineLocale('en-gb', enGbLocale);
defineLocale('pl', plLocale);
defineLocale('pl-pl', plLocale);
defineLocale('ro', roLocale);
defineLocale('ro-ro', roLocale);

@NgModule({
   declarations: [EventNamePipe, TranslatePipe, ResultFilterComponent, SubmitComponent, SubmitFormComponent],
   imports: [
      CommonModule,
      AlertModule,
      IconModule,
      LocaleModule,
      ModalModule,
      NavbarModule,
      SelectModule,
      TableModule,
      FormsModule,
      ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
      BsDatepickerModule.forRoot(),
      TimepickerModule.forRoot(),
      TabsModule.forRoot(),
      LocaleModule,
   ],
   exports: [
      EventNamePipe,
      TranslatePipe,
      ResultFilterComponent,
      SubmitFormComponent,
      CommonModule,
      NavbarModule,
      IconModule,
      AlertModule,
      TableModule,
      SelectModule,
      ModalModule,
      FormsModule,
      ReactiveFormsModule,
      BsDatepickerModule,
      TimepickerModule,
      TabsModule,
      LocaleModule,
   ],
})
export class MainModule {}
