import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbookComponent } from './callbook/callbook.component';
import { AwardComponent } from './award/award.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { QslMemberComponent } from './qsl-member/qsl-member.component';
import { ReviewQsosComponent } from './review-qsos/review-qsos.component';
import { CanActivateAdminService } from './services/can-activate-admin.service';
import { CanActivateEvalService } from './services/can-activate-eval.service';
import { CanActivateSysadminService } from './services/can-activate-sysadmin.service';
import { SubmitComponent } from './submit/submit.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { QsoTyperComponent } from './qso-typer/qso-typer.component';
import { LoggerComponent } from './logger/logger.component';

const routes: Routes = [
   { path: '', component: HomeComponent },
   { path: 'submit/:event', component: SubmitComponent },
   { path: 'logger/:event', component: LoggerComponent },
   { path: 'callbook', component: CallbookComponent },
   { path: 'qsl-member', component: QslMemberComponent },
   { path: 'award', component: AwardComponent },
   { path: 'online-logger/:event/:id', component: QsoTyperComponent },
   { path: 'login', component: LoginComponent },
   { path: 'change-pwd', component: ChangePasswordComponent },
   { path: 'review-qsos/:upload', component: ReviewQsosComponent },
   { path: 'review-qsos/:upload/admin', component: ReviewQsosComponent, canActivateChild: [CanActivateAdminService] },
   {
      path: 'admin',
      loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      canActivateChild: [CanActivateAdminService],
   },
   {
      path: 'eval',
      loadChildren: () => import('./eval/eval.module').then((m) => m.EvalModule),
      canActivateChild: [CanActivateEvalService],
   },
   {
      path: 'sysadmin',
      loadChildren: () => import('./sysadmin/sysadmin.module').then((m) => m.SysadminModule),
      canActivateChild: [CanActivateSysadminService],
   },
   { path: 'results', loadChildren: () => import('./results/results.module').then((m) => m.ResultsModule) },
   { path: 'stats', loadChildren: () => import('./stats/stats.module').then((m) => m.StatsModule) },
   { path: '**', component: NotFoundComponent },
];

@NgModule({
   imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
   exports: [RouterModule],
})
export class AppRoutingModule {}
