import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpService, isResultValid, translate, TranslatePipe } from '@szegedsw/http.service';
import { ButtonType, InitButton, Locales, ModalHandler, translateModal } from '@szegedsw/modal';
import { AppComponent } from '../app.component';
import { api } from '../config/config';
import { Event } from '../entities/event.entity';
import {
   navCallbook,
   navHandler,
   navLogout,
   navOnlineLogger,
   navResult,
   navRules,
   navStatistics,
   navSubmit,
   site,
} from './nav.handler';

@Component({
   selector: 'app-nav',
   templateUrl: './nav.component.html',
   styleUrls: ['./nav.component.css'],
})
export class NavComponent implements OnInit {
   navHandler = navHandler;
   logout: ModalHandler = new ModalHandler();

   private eventService: HttpService;
   private eventsService: HttpService;

   constructor(private http: HttpClient) {
      this.eventService = new HttpService(this.http, api('nest', 'events/list'));
      this.eventsService = new HttpService(this.http, api('nest', 'events'));
      this.logout.title = 'Logout';
      this.logout.text = 'Are you sure to log out?';
      this.logout.classes = ['modal-dialog-centered'];
      [this.logout.buttons] = InitButton({ type: ButtonType.YesCancel, prefix: 'logout' }, 1);
      TranslatePipe.changed.subscribe(() => {
         this.logout.change.next(translateModal(this.logout, translate));
         // this.changePassword.change.next(translateModal(this.changePassword, translate));
      });
   }

   ngOnInit() {
      navLogout();
      this.eventService.get<Event[]>({ site }, async (res) => {
         if (isResultValid(res)) {
            AppComponent.setEvents(res.body);
            if (await AppComponent.getEvents()) {
               await navSubmit();
               await navOnlineLogger();
               await navResult();
               await navRules();
               await navStatistics();
               await navCallbook();
            }
         }
      });
   }

   onButton(event: string) {
      switch (event) {
         case 'logout': {
            this.logout.event.next();
            break;
         }
         case 'eval-chooser': {
            window.open(`/eval/chooser`, '_blank');
            break;
         }
         case Locales.en: {
            AppComponent.languageEvent.next(Locales.en);
            break;
         }
         case Locales.hu: {
            AppComponent.languageEvent.next(Locales.hu);
            break;
         }
         case 'sync': {
            AppComponent.syncEvent.next();
            break;
         }
         case 'bull-dashboard': {
            window.open('/nest/bull', '_blank');
         }
      }
      if (event.startsWith('link-')) {
         window.open(event.slice(5), '_blank');
      }
      if (event.startsWith('file-')) {
         this.eventsService.subRoute('/download').download({ fileName: event.slice(5) }, (res) => undefined);
      }
   }

   onLogout(event) {
      if (event.getKey() === 'logout-yes') {
         AppComponent.logoutEvent.next();
      }
   }
}
