import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService, TranslatePipe, isResultValid, translate } from '@szegedsw/http.service';
import { ButtonType, InitButton, ModalHandler, translateModal } from '@szegedsw/modal';
import { ElemList, getControl, getInvalid } from '@szegedsw/select';
import moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { api, callsignRegExp } from '../config/config';
import { Category } from '../entities/category.entity';
import { CategoryType } from '../entities/category.type.entity';
import { Event, Events, GetCategoryTypes, GetSubmit, GetSubmitOpt } from '../entities/event.entity';
import { Upload } from '../entities/upload.entity';

@Component({
   selector: 'app-submit',
   templateUrl: './submit.component.html',
   styleUrls: ['./submit.component.css'],
})
export class SubmitComponent implements OnInit {
   translate = translate;
   event: GetSubmit & GetSubmitOpt;
   categoryTypesInput: GetCategoryTypes['categoryTypes'];
   filterCategories: string[] = [];
   categoryTypes: CategoryType[] = [];
   events: Event[];

   elemList: ElemList = [];

   submitForm = this.fb.group({
      information: this.fb.control(false, Validators.required),
      callsign: this.fb.control(
         { value: '', disabled: false },
         {
            validators: [Validators.required, Validators.pattern(callsignRegExp)],
            asyncValidators: [
               (control) => {
                  return new Promise((resolve) => {
                     if (this.event.preserveCategories.length > 0) {
                        new HttpService(this.http, api('nest', 'submit/preserve-categories')).get<{ categories: any[] }>(
                           { eventId: this.event._id, callsign: control.value },
                           (res) => {
                              if (isResultValid(res)) {
                                 this.resetEvent.next();
                                 this.filterCategories = res.body.categories;
                              }
                              resolve(undefined);
                           },
                        );
                     }
                     resolve(undefined);
                  });
               },
            ],
            updateOn: 'blur',
         },
      ),
      email: this.fb.control('', [Validators.required, Validators.email]),
      operators: this.fb.control('', Validators.required),
      policy: this.fb.control(false, Validators.required),
      files: this.fb.array([]),
      sentNumberField: this.fb.control('', [
         (control) => (this.event && this.event[Events.isSentNumberField] && !control.value ? { required: true } : null),
      ]),
      sentStringField: this.fb.control('', [
         (control) => (this.event && this.event[Events.isSentStringField] && !control.value ? { required: true } : null),
      ]),
      stationSponsor: this.fb.control(''),
   });

   loadEvent: Subject<void> = new Subject<void>();
   loadObs = this.loadEvent.asObservable();
   disableEvent: Subject<boolean> = new Subject<boolean>();
   disableObs = this.disableEvent.asObservable();
   loadTypes = new Subject<void>();
   loadTypesObs = this.loadTypes.asObservable();
   resetEvent = new Subject<void>();
   resetObs = this.resetEvent.asObservable();

   errorModal = new ModalHandler();
   tooLargeModal = new ModalHandler();
   progressModal = new ModalHandler();
   expiredModal = new ModalHandler();
   modalOpenedFor = '';
   optionalCategories: Category[] = [];
   chosenCategoriesFromModal: string[] = [];

   logs: File[];
   recordings: File[];

   submitService: HttpService;
   isAdminSubmit: boolean;
   isExpired: boolean;
   isNotAttached: boolean;
   isInformationInvalid: boolean;
   isPolicyInvalid: boolean;

   uploadId: string;

   constructor(private http: HttpClient, private route: ActivatedRoute, private fb: FormBuilder, private router: Router) {
      this.isAdminSubmit = this.route.snapshot.url.some((u) => u.path === 'admin-submit');
      this.errorModal.title = 'Log cannot be processed!';
      this.errorModal.text = 'We could not process your log file. Please check that it is the correct format.';
      this.errorModal.closeButton = false;
      this.errorModal.keyboard = false;
      this.errorModal.ignoreBackdropClick = true;
      this.errorModal.classes = ['modal-dialog-centered', 'modal-lg'];

      this.tooLargeModal.title = 'Uploaded file is too large!';
      this.tooLargeModal.text = 'We could not save your file. Maximum size can be 50 MB.';
      this.tooLargeModal.closeButton = false;
      this.tooLargeModal.keyboard = false;
      this.tooLargeModal.ignoreBackdropClick = true;
      this.tooLargeModal.classes = ['modal-dialog-centered', 'modal-lg'];

      this.progressModal.title = 'Log is being processed...';
      this.progressModal.text = 'Please wait for the redirection in background and check your uploaded log.';
      this.progressModal.closeButton = false;
      this.progressModal.keyboard = false;
      this.progressModal.ignoreBackdropClick = true;
      this.progressModal.classes = ['modal-dialog-centered', 'modal-lg'];

      this.expiredModal.title = 'Log submission expired!';
      this.expiredModal.text = 'Submitting log is not possible for this event anymore.';

      [this.errorModal.buttons, this.expiredModal.buttons, this.progressModal.buttons, this.tooLargeModal.buttons] = InitButton(
         { type: ButtonType.Ok, prefix: 'upload' },
         4,
      );
      translateModal(this.errorModal, translate);
      translateModal(this.expiredModal, translate);
      translateModal(this.progressModal, translate);
      translateModal(this.tooLargeModal, translate);
      TranslatePipe.changed.subscribe(() => {
         this.errorModal.change.next(translateModal(this.errorModal, translate));
         this.expiredModal.change.next(translateModal(this.expiredModal, translate));
         this.progressModal.change.next(translateModal(this.progressModal, translate));
         this.tooLargeModal.change.next(translateModal(this.tooLargeModal, translate));
      });
   }

   async ngOnInit() {
      this.events = await AppComponent.getEvents();
      if (!this.isAdminSubmit) {
         this.events = this.events.filter((event) => event.isSubmit);
      }
      const fileGroup = () => this.fb.group({ log: '', recording: '' });
      this.route.paramMap.subscribe((params) => {
         new HttpService(this.http, api('nest', 'submit/event-details')).get<
            { event: GetSubmit & GetSubmitOpt } & GetCategoryTypes
         >({ eventId: params.get('event') }, (res) => {
            this.initValues();
            if (isResultValid(res)) {
               this.event = res.body.event;
               this.event.submitDeadline = moment(this.event.submitDeadline).utc().format('YYYY-MM-DD HH:mm:ss UTC') as any;
               this.categoryTypesInput = res.body.categoryTypes;
               const files = this.submitForm.get('files') as FormArray;
               while (files.length > 0) {
                  files.removeAt(0);
               }
               [...Array(this.event.rounds.length)].map(() => files.push(fileGroup()));
               this.logs = new Array(this.event.rounds.length);
               this.recordings = new Array(this.event.rounds.length);
               // this.loadEvent.next();
               this.categoryTypes = this.categoryTypesInput.map((categoryType) => categoryType.type) as CategoryType[];
               setTimeout(() => {
                  this.submitForm.get('event').setValue(this.event._id);
                  this.loadTypes.next();
                  this.disableEvent.next(true);
                  if (!this.events.map((event) => event._id).includes(this.event._id)) {
                     this.expiredModal.event.next();
                  }
               }, 1000);
            }
         });
      });
   }

   onFileChange(event: any, variable: string, id: number) {
      if (event.target.files.length > 0) {
         this[variable][id] = event.target.files[0];
      } else {
         this[variable][id] = undefined;
      }
   }

   isFormInvalid(): boolean {
      if (!this.events || !this.event || !this.events.map((contest) => contest._id).includes(this.event._id)) {
         return true;
      } else if (this.submitForm && (this.submitForm.get('callsign').invalid || this.submitForm.get('email').invalid)) {
         return true;
      } else if (getInvalid(this.elemList)) {
         return true;
      } else if (
         this.submitForm &&
         this.event.isOperatorList &&
         (this.isMultiChosen() || this.event.isOperatorListAlways) &&
         this.submitForm.get('operators').invalid
      ) {
         return true;
      } else if (
         this.submitForm &&
         (this.submitForm.get('sentNumberField').invalid || this.submitForm.get('sentStringField').invalid)
      ) {
         return true;
      } else if (!this.logs || this.logs.every((value) => value === undefined)) {
         return true;
      } else if (this.submitForm && !this.submitForm.get('policy').value) {
         return true;
      }
      return false;
   }

   onVerify() {
      if (!this.events || !this.event || !this.events.map((contest) => contest._id).includes(this.event._id)) {
         this.isExpired = true;
         return;
      }
      if (this.submitForm && !this.submitForm.get('information').value) {
         this.isInformationInvalid = true;
         return;
      }
      if (this.submitForm && (this.submitForm.get('callsign').invalid || this.submitForm.get('email').invalid)) {
         this.submitForm.get('callsign').markAsTouched();
         this.submitForm.get('email').markAsTouched();
         return;
      }
      if (getInvalid(this.elemList)) {
         this.elemList.forEach((e) => e.control.markAsTouched());
         return;
      }
      if (
         this.submitForm &&
         this.event.isOperatorList &&
         (this.isMultiChosen() || this.event.isOperatorListAlways) &&
         this.submitForm.get('operators').invalid
      ) {
         this.submitForm.get('operators').markAsTouched();
         return;
      }
      if (this.submitForm && this.event[Events.isSentNumberField] && this.submitForm.get('sentNumberField').invalid) {
         this.submitForm.get('sentNumberField').markAsTouched();
         return;
      }
      if (this.submitForm && this.event[Events.isSentStringField] && this.submitForm.get('sentStringField').invalid) {
         this.submitForm.get('sentStringField').markAsTouched();
         return;
      }
      if (!this.logs || this.logs.every((value) => value === undefined)) {
         this.isNotAttached = true;
         return;
      } else {
         this.isNotAttached = false;
      }
      if (this.submitForm && !this.submitForm.get('policy').value) {
         this.isPolicyInvalid = true;
         return;
      }
   }

   onSubmit() {
      this.progressModal.event.next();
      const files = this.logs.concat(this.recordings).filter((file) => file !== undefined);
      new HttpService(
         this.http,
         `${api('nest', 'submit/upload')}/${this.event[Events._id]}/${encodeURIComponent(this.submitForm.get('callsign').value)}`,
      ).upload<{ files: { _id: string }[]; _id: string }>(files, (listener) => {
         listener.subscribe((res) => {
            if (res instanceof HttpResponse && isResultValid(res)) {
               const operators = this.event.isOperatorList ? this.submitForm.get('operators').value : undefined;
               this.uploadId = res.body._id;
               new HttpService(this.http, api('nest', 'submit/claim')).post<{ upload: Upload }>(
                  {
                     uploadId: this.uploadId,
                     callsign: this.submitForm.get('callsign').value,
                     email: this.submitForm.get('email').value,
                     operators: operators ? operators : undefined,
                     event: getControl(this.elemList, 'event').value,
                     categories: this.elemList
                        .reduce<string[]>((previous, current) => {
                           if (current.name !== 'event' && current.control.value !== -1) {
                              previous.push(current.control.value);
                           }
                           return previous;
                        }, [])
                        .concat(this.chosenCategoriesFromModal),
                     files: files.map((file) => ({
                        file: res.body.files[files.indexOf(file)]._id,
                        round: this.event.rounds[Math.max(this.logs.indexOf(file), this.recordings.indexOf(file))]._id,
                     })),
                     sentNumberField: this.event[Events.isSentNumberField]
                        ? this.submitForm.get('sentNumberField').value
                        : undefined,
                     sentStringField: this.event[Events.isSentStringField]
                        ? this.submitForm.get('sentStringField').value
                        : undefined,
                     isAdminSubmit: this.isAdminSubmit ? true : undefined,
                     stationSponsor: this.submitForm.get('stationSponsor').value
                        ? this.submitForm.get('stationSponsor').value
                        : undefined,
                     language: TranslatePipe.current(),
                  },
                  async (postRes) => {
                     if (isResultValid(postRes)) {
                        let claimed = false;
                        let error = false;
                        while (!claimed && !error) {
                           await new Promise<void>((resolve) => {
                              setTimeout(() => {
                                 new HttpService(this.http, api('nest', 'submit/claim')).get<any>(
                                    { uploadId: this.uploadId },
                                    (res) => {
                                       if (isResultValid(res)) {
                                          claimed = true;
                                       }
                                       if (res.status === 422) {
                                          error = true;
                                       }
                                       if (res.status === 409) {
                                          this.errorModal.title = translate('You may have mistyped the callsign');
                                          // eslint-disable-next-line max-len
                                          this.errorModal.text = `${translate(
                                             'The submitted callsign and the one in the log file are not the same.',
                                          )} ${translate('Error')}: '${(res as unknown as HttpErrorResponse).error.message}'`;
                                          this.errorModal.change.next(this.errorModal);
                                          error = true;
                                       }
                                       if (res.status === 406) {
                                          this.errorModal.title = this.event.sentStringFieldName + translate(' is not valid!');
                                          this.errorModal.text =
                                             'Please provide a valid value for: ' + this.event.sentStringFieldName;
                                          this.errorModal.change.next(this.errorModal);
                                          error = true;
                                       }
                                       resolve();
                                    },
                                 );
                              }, 500);
                           });
                        }
                        if (error) {
                           this.errorModal.event.next();
                        } else {
                           if (this.isAdminSubmit) {
                              this.router.navigate(['review-qsos', this.uploadId, 'admin']);
                           } else {
                              this.router.navigate(['review-qsos', this.uploadId]);
                           }
                        }
                     }
                  },
               );
            } else if (res instanceof HttpErrorResponse) {
               if (res.status === 413) {
                  this.tooLargeModal.event.next();
                  return;
               } else if (res.status === 406) {
                  this.errorModal.title = translate('You uploaded a wrong file format');
                  // eslint-disable-next-line max-len
                  this.errorModal.text = `${translate('Error')}: '${(res as unknown as HttpErrorResponse).error.message}'`;
                  this.errorModal.change.next(this.errorModal);
               }
               this.errorModal.event.next();
            }
         });
      });
   }

   onCancel() {
      this.initValues();
   }

   initValues() {
      this.chosenCategoriesFromModal = [];
      this.submitForm.get('callsign').reset();
      this.submitForm.get('email').reset();
      this.submitForm.get('operators').reset();
      this.submitForm.get('policy').setValue(false);
      this.resetEvent.next();
      if (this.logs) {
         this.logs.fill(undefined);
      }
      if (this.recordings) {
         this.recordings.fill(undefined);
      }
      (this.submitForm.get('files') as FormArray).reset();
      this.filterCategories = [];
      this.submitForm.get('sentNumberField').reset();
      this.submitForm.get('sentStringField').reset();
      this.submitForm.get('stationSponsor').reset();
      this.submitForm.get('information').setValue(false);
   }

   isMultiChosen() {
      return this.elemList.some((e) =>
         this.categoryTypesInput.some((c) => {
            return c.categories.some((a) => a._id === e.control.value && a.isMultiOperator);
         }),
      );
   }

   onChosenCategoriesFromModalChange(event: string[]) {
      this.chosenCategoriesFromModal = event;
   }
}
