import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpService, isResultValid, translate } from '@szegedsw/http.service';
import { IconClass } from '@szegedsw/icon';
import { ElemList } from '@szegedsw/select';
import { addIcon, convertToBool } from '@szegedsw/table';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { api } from '../../config/config';
import { Qso } from '../../qso-typer/qso-typer.component';

@Component({
   selector: 'app-offline-qso-form',
   templateUrl: './offline-qso-form.component.html',
   styleUrls: ['./offline-qso-form.component.css'],
})
export class OfflineQsoFormComponent implements OnInit {
   @Input('contestName') contestName?: string;
   @Input('ownCallsign') ownCallsign: string;
   @Input('eventId') eventId: string;
   @Input('logId') logId: string;
   @Input('qsos') qsos: Qso[];
   @Output('qsos') qsosChange = new EventEmitter<Qso[]>();
   @Input('renderTableContent') renderTableContent: boolean;
   @Output('renderTableContent') renderTableContentChange = new EventEmitter<boolean>();
   @Input('isOffline') isOffline: boolean;
   @Output('isOffline') isOfflineChange = new EventEmitter<boolean>();
   @Input('loadBandEvent') loadBandEventObservable: Observable<void>;
   @Input('loadModeEvent') loadModeEventObservable: Observable<void>;
   @Input('resetEvent') resetEventObservable: Observable<void>;
   @Input('band') band: any[];
   @Input('mode') mode: any[];
   @Input('offlineQsos') offlineQsos: Qso[];
   @Output('offlineQsos') offlineQsosChange = new EventEmitter<Qso[]>();
   @Input('qsoForm') qsoForm: FormGroup;
   @Output('qsoForm') qsoFormChange = new EventEmitter<FormGroup>();

   today = 0;

   date = new Date();

   bsConfig: Partial<BsDatepickerConfig> = {
      containerClass: environment.production ? 'theme-blue' : 'theme-red',
      isAnimated: true,
      adaptivePosition: true,
      dateInputFormat: 'YYYY-MM-DD',
   };

   elemList: ElemList = [];

   private loadBandEventSubscription: Subscription;
   private loadModeEventSubscription: Subscription;
   private resetEventSubscription: Subscription;

   loadBandEvent: Subject<string> = new Subject<string>();
   loadModeEvent: Subject<string> = new Subject<string>();
   resetEvent: Subject<string> = new Subject<string>();
   loadBandObs = this.loadBandEvent.asObservable();
   loadModeObs = this.loadModeEvent.asObservable();
   resetObs = this.resetEvent.asObservable();

   logService: HttpService;

   constructor(private http: HttpClient) {
      this.logService = new HttpService(this.http, api('nest', 'qso/online-logger'));
   }

   ngOnInit() {
      this.loadBandEventSubscription = this.loadBandEventObservable.subscribe(() => this.loadBandEvent.next());
      this.loadModeEventSubscription = this.loadModeEventObservable.subscribe(() => this.loadModeEvent.next());
      this.resetEventSubscription = this.resetEventObservable.subscribe(() => this.resetEvent.next());
      this.qsoForm.get('sRst').setValue(59);
      this.qsoForm.get('rRst').setValue(59);
      setInterval(() => {
         this.today = Date.now();
         this.date = new Date(this.today);
         this.qsoForm.get('date').setValue(this.date);
         this.qsoForm.get('time').setValue(new Date(new Date(this.date).getTime() + new Date().getTimezoneOffset() * 60 * 1000));
         this.qsoForm.get('mode').setValue(0);
         this.qsoForm.get('freq').setValue(0);
      }, 1000);
   }

   ngOnDestroy() {
      this.loadBandEventSubscription.unsubscribe();
      this.loadModeEventSubscription.unsubscribe();
      this.resetEventSubscription.unsubscribe();
   }

   toDate(date: Date): string {
      return formatDate(date, 'yyyy-MM-dd', 'hu');
   }

   toTime(date: Date): string {
      return formatDate(date, 'HH:mm', 'hu');
   }

   onSend() {
      const form = this.qsoForm;
      form.markAllAsTouched();
      form.updateValueAndValidity();
      if (form.valid) {
         let data: Qso = undefined;

         data = this.qsoForm.getRawValue();
         Object.assign(data, { callsign: data.call });
         const offset = new Date().getTimezoneOffset() * 60000;
         let dateTime = new Date(
            new Date(data.date).getFullYear(),
            new Date(data.date).getMonth(),
            new Date(data.date).getDate(),
            new Date(data.time).getHours(),
            new Date(data.time).getMinutes(),
            new Date(data.time).getSeconds(),
         );
         dateTime = new Date(new Date().setTime(new Date(dateTime).getTime() - offset));
         Object.assign(data, { dateTime: dateTime.toISOString() });
         data.rRst = data.rRst.toString();
         data.sRst = data.sRst.toString();
         data = { ...data, nr: this.qsos[0] ? this.qsos[0].nr + 1 : 1 };
         data.mode = 'FM';
         this.logService.post<Qso>({ eventId: this.eventId, logId: this.logId, qsos: [{ ...data }] }, (res) => {
            if (isResultValid(res)) {
               const qso: Qso = res.body[0];
               data._id = qso._id;
               data.status = qso.status;
               data.dxcc = qso.dxcc;
               data.callsign = qso.callsign;
               data.multis = qso.multis;
               data.validation = qso.validation;
               data.xQso = false;
               data.date = this.toDate(new Date(data.date));
               data.time = this.toTime(new Date(data.time));
               this.qsos.unshift(data);
               addIcon(this.qsos, 'edit', new IconClass('fas:faEdit', 'edit', [], translate('Modify QSO')));
               addIcon(this.qsos, 'delete', new IconClass('fas:faTimes', 'delete', [], translate('Delete QSO')));
               convertToBool(this.qsos, ['dupe', 'xQso']);
               this.qsoForm.get('sNum').setValue(this.qsoForm.get('sNum').value + 1);
               this.qsoForm.get('rNum').reset();
               this.qsoForm.get('call').reset();
            } else {
               data.offline = true;
               this.isOffline = true;
               data.xQso = false;
               data.date = this.toDate(new Date(data.date));
               data.time = this.toTime(new Date(data.time));
               this.offlineQsos.push(data);
               this.qsos.unshift(data);
               addIcon(this.qsos, 'edit', new IconClass('fas:faEdit', 'edit', [], translate('Modify QSO')));
               addIcon(this.qsos, 'delete', new IconClass('fas:faTimes', 'delete', [], translate('Delete QSO')));
               convertToBool(this.qsos, ['dupe', 'xQso']);
               this.qsoForm.get('sNum').setValue(this.qsoForm.get('sNum').value + 1);
               this.qsoForm.get('rNum').reset();
               this.qsoForm.get('call').reset();
            }
         });
      }
      this.onChangeValues();
   }

   onChangeValues() {
      this.qsosChange.emit(this.qsos);
      this.offlineQsosChange.emit(this.offlineQsos);
      this.isOfflineChange.emit(this.isOffline);
      this.qsoFormChange.emit(this.qsoForm);
   }
}
