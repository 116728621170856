import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { translate } from '@szegedsw/http.service';
import { Body, ButtonType, ControlType, InitButton, ModalHandler, translateModal } from '@szegedsw/modal';
import { ElemList, getControl, getElement } from '@szegedsw/select';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { callsignRegExp } from '../config/config';
import { Category } from '../entities/category.entity';
import { CategoryType } from '../entities/category.type.entity';
import { EvalCategories } from '../entities/eval.category.entity';
import { Event, GetCategoryTypes, GetSubmit, GetSubmitOpt } from '../entities/event.entity';

@Component({
   selector: 'app-submit-form',
   templateUrl: './submit-form.component.html',
   styleUrls: ['./submit-form.component.css'],
})
export class SubmitFormComponent {
   translate = translate;
   @Input('submitForm') submitForm: FormGroup | any;
   @Input('elemList') elemList: ElemList;
   @Input('events') events: Event;
   @Input('categoryTypes') categoryTypes: CategoryType[];
   @Input('modalOpenedFor') modalOpenedFor: string;
   @Input('chosenCategoriesFromModal') chosenCategoriesFromModal: string[];
   @Output() onChosenCategoriesFromModalChange = new EventEmitter<string[]>();
   @Input('isNotAttached') isNotAttached: boolean;
   @Input('isPolicyInvalid') isPolicyInvalid: boolean;
   @Input('loadObs') loadObs: any;
   @Input('disableObs') disableObs: any;
   @Input('loadTypesObs') loadTypesObs: any;
   @Input('resetObs') resetObs: any;
   @Input('filterCategories') filterCategories: string[] = [];
   @Input('event') event: GetSubmit & GetSubmitOpt;
   @Input('categoryTypesInput') categoryTypesInput: GetCategoryTypes['categoryTypes'];
   @Input('optionalCategories') optionalCategories: Category[] = [];
   @Input('isMultiChosen') isMultiChosen: boolean;
   @Input('resetEvent') resetEvent: Subject<void>;

   optionsModal = new ModalHandler();
   callsingsOK = true;

   constructor() {
      this.optionsModal.title = 'Category options';
      this.optionsModal.ignoreBackdropClick = true;
      this.optionsModal.keyboard = false;
      this.optionsModal.closeButton = false;
      this.optionsModal.classes = ['modal-dialog-centered'];

      this.optionsModal.buttons = InitButton({ type: ButtonType.CancelOk, prefix: 'options' }, 1)[0];
   }

   chosenCategories() {
      return this.elemList
         .reduce<string[]>((previous, current) => {
            if (current.name !== 'event') {
               previous.push(current.control.value);
            }
            return previous;
         }, [])
         .concat(this.chosenCategoriesFromModal);
   }

   getCategories(type: CategoryType) {
      if (this.event.isCategoryCheck) {
         const chosen = this.chosenCategories();
         const evalCategories = this.event.evalCategories.filter((e) =>
            chosen.filter((c) => typeof c === 'string').every((c) => (e.categories as unknown as string[]).includes(c)),
         );
         const categories: string[] = evalCategories.reduce((p, c) => {
            p.push(...c.categories);
            return p;
         }, []);
         return _.uniq(categories)
            .filter(
               (c) =>
                  (this.categoryTypesInput.find((t) => {
                     return t.type._id === type._id && t.categories.find((cat) => cat._id === c);
                  }) &&
                     this.filterCategories.length === 0) ||
                  this.filterCategories.includes(c),
            )
            .map((c) => {
               return {
                  _id: c,
                  name: this.categoryTypesInput.map((t) => t.categories.find((cat) => cat._id === c)).find((s) => s).name,
               };
            });
      }
      return this.categoryTypesInput
         .find((category) => category.type._id === type._id)
         .categories.filter((category) => this.filterCategories.length === 0 || this.filterCategories.includes(category._id));
   }

   getEvalCategories() {
      const chosen = this.chosenCategories();
      return this.event.evalCategories
         .filter((e) => e[EvalCategories.categories].every((c) => chosen.includes(String(c))))
         .map((e) => e[EvalCategories.name]);
   }

   onCategoryChanged(event) {
      this.categoryTypesInput.some((e) => {
         return e.categories.some((c) => {
            if (c._id === event && c.optionalCategories.length) {
               this.modalOpenedFor = e.type.code;
               this.optionsModal.text = translate(`Please check maximum $1 categories!`).replace('$1', c.maxOptions.toString());
               this.optionsModal.body = c.optionalCategories.map((o, index) => ({
                  id: index.toString(),
                  type: ControlType.checkbox,
                  value: false,
                  placeHolder: o.name,
               }));
               this.optionalCategories = c.optionalCategories;
               this.optionsModal.validators = [
                  (control) => {
                     const length = (control.value as boolean[]).filter((v) => v).length;
                     if (length > c.maxOptions) {
                        return { checkLess: true };
                     } else if (length === 0) {
                        return { pleaseChoose: true };
                     }
                  },
               ];
               this.optionsModal.errors = [
                  ['checkLess', translate(`Please check maximum $1 categories!`).replace('$1', c.maxOptions.toString())],
               ];
               this.optionsModal.change.next(translateModal(this.optionsModal, translate));
               this.optionsModal.event.next();
               this.chosenCategoriesFromModal = c.optionalCategories.map((oe) => oe._id);
               return true;
            } else if (e.categories.find((t) => t._id === event) && e.categories.some((t) => t.optionalCategories.length > 0)) {
               this.chosenCategoriesFromModal = [];
            }
            return false;
         });
      });
   }

   getOptionalCategoryName(id: string) {
      return this.optionalCategories.find((o) => o._id === id).name;
   }

   onResetCategories() {
      this.resetEvent.next();
   }

   onCategoryButton(event: Body) {
      if (event.getKey() === 'options-cancel') {
         // tslint:disable-next-line: max-line-length
         getControl(this.elemList, this.modalOpenedFor).setValue(-1);
         this.chosenCategoriesFromModal = [];
      } else {
         this.chosenCategoriesFromModal = Object.keys(event.getPatchValues())
            .filter((k) => event.getPatchValues()[k])
            .map((k) => this.optionalCategories[Number(k)]._id);
         this.onChosenCategoriesFromModalChange.next(this.chosenCategoriesFromModal);
      }
      getElement(this.elemList, this.modalOpenedFor).nativeElement.focus();
      this.modalOpenedFor = '';
   }

   operatorsCheck() {
      const callsignList = (this.submitForm.get('operators').value as string)
         .split('.')
         .join(' ')
         .split(',')
         .join(' ')
         .split('/')
         .join(' ')
         .split(/\s+/);

      const regexp = new RegExp(callsignRegExp);
      callsignList.forEach((c) => {
         this.callsingsOK = this.callsingsOK && regexp.test(c);
      });
   }
}
