import { isResultValid, translate, TranslatePipe } from '@szegedsw/http.service';
import { IconClass } from '@szegedsw/icon';
import { addIcon, center, right, splitDateTimeToLocale } from '@szegedsw/table';
import { ClaimedQsosComponent as EvalClaimedQsos } from '../eval/claimed-qsos/claimed-qsos.component';
import { PreliminaryQsosComponent as EvalPreliminaryQsos } from '../eval/preliminary-qsos/preliminary-qsos.component';
import { ReverseQsosComponent } from '../eval/reverse-qsos/reverse-qsos.component';
import { ResultsQsos } from '../entities/claimed.entity';
import { Qsos as QsosEnum } from '../entities/qso.entity';
import { ClaimedQsosComponent as ResultsClaimedQsos } from '../results/claimed-qsos/claimed-qsos.component';
import { PreliminaryQsosComponent as ResultsPreliminaryQsos } from '../results/preliminary-qsos/preliminary-qsos.component';

export function qsosOnInit(
   Qsos: EvalClaimedQsos | ResultsClaimedQsos | EvalPreliminaryQsos | ResultsPreliminaryQsos | ReverseQsosComponent,
) {
   Qsos.queryParams = Qsos.route.snapshot.queryParams;
   const paramMap = Qsos.route.snapshot.paramMap;
   Qsos.params = new Map(paramMap.keys.map<[string, string]>((key) => [key, decodeURIComponent(paramMap.get(key))]));
   const isResults = Qsos instanceof ResultsPreliminaryQsos || Qsos instanceof ResultsClaimedQsos;
   const isPreliminary = Qsos instanceof EvalPreliminaryQsos || Qsos instanceof ResultsPreliminaryQsos;
   const isReverse = Qsos instanceof ReverseQsosComponent;
   // renavigate, to avoid re-encoding the URI parameters
   Qsos.router.navigate(
      [
         isResults ? '/results' : '/eval',
         Qsos.params.get('event'),
         isPreliminary ? 'preliminary-qsos' : isReverse ? 'reverse-qsos' : 'claimed-qsos',
         Qsos.params.get('callsign'),
      ].concat(!isReverse ? Qsos.params.get('code') : []),
      { queryParams: Qsos.queryParams },
   );
   Qsos.service.get<ResultsQsos>(
      {
         eventId: Qsos.params.get('event'),
         callsign: Qsos.params.get('callsign'),
         roundCode: !isReverse ? Qsos.params.get('code') : undefined,
         isClaimed: Qsos instanceof EvalClaimedQsos || Qsos instanceof ResultsClaimedQsos,
      },
      (res) => {
         if (isResultValid(res)) {
            Qsos.rows = res.body.qsos;
            Qsos.rows.forEach((row, index) => {
               row.rExch = (row.rNum ? String(row.rNum) : '') + ' ' + (row.rExch ? row.rExch : '');
               row.sExch = (row.sNum ? String(row.sNum) : '') + ' ' + (row.sExch ? row.sExch : '');
               row.index = `${index + 1}.`;
            });
            if (isPreliminary) {
               addIcon(Qsos.rows, 'comment', new IconClass('fas:faComment', 'comment', undefined, '$comment'), ['comment']);
            }
            if (!Qsos.rows.some((row) => row.freq) && Qsos.rows.some((row) => row.band)) {
               Qsos.headers.rows[0].splice(3, 1, right(translate('Band')));
               Qsos.codes.fields.splice(3, 1, right('band'));
            }
            if (
               Qsos.rows.some((row) => row.rWWL && row.rWWL.length > 0) &&
               Qsos.codes.fields.findIndex((field) => field.str === 'rWWL')
            ) {
               Qsos.headers.rows[0].splice(10, 0, center(translate('Locator')));
               Qsos.codes.fields.splice(10, 0, center('rWWL'));
            }
            Qsos.details = [{ ...res.body.round, ...res.body.log, ...res.body.score }];
            if (
               Object.keys(Qsos.details[0]).includes('WWL') &&
               Qsos.detailCodes.fields.findIndex((field) => field.str === 'WWL') < 0
            ) {
               Qsos.detailHeaders.rows[0].splice(1, 0, { str: translate('Locator') });
               Qsos.detailCodes.fields.splice(1, 0, { str: 'WWL' });
            }
            splitDateTimeToLocale(Qsos.rows, [QsosEnum.dateTime], TranslatePipe.getLocale(), [
               { timeZone: 'UTC' },
               { timeZone: 'UTC', hour: '2-digit', minute: '2-digit' },
            ]);
            if (res.body.log.evalCategories.some(({ isSWL }) => isSWL)) {
               Qsos.headers.rows[0].splice(8, 0, center(translate('Heard callsign')));
               Qsos.codes.fields.splice(8, 0, center('heardCallsign'));
            }
            Qsos.rows.forEach((r) => (r.multis = r.multis.join(', ') as any));
         }
      },
   );
}
