import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService, isResultValid, translate } from '@szegedsw/http.service';
import { ElemList } from '@szegedsw/select';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { api } from 'src/app/config/config';
import { DXCCList } from 'src/app/entities/dxcc.entity';
import { EventEvals } from 'src/app/entities/eval.category.entity';
import { IResultFilter } from 'src/app/entities/event.entity';
import { IFilter } from 'src/app/entities/result.entity';

@Component({
   selector: 'app-result-filter',
   templateUrl: './result-filter.component.html',
   styleUrls: ['./result-filter.component.css'],
})
export class ResultFilterComponent implements OnInit, OnDestroy {
   @Input() event: string;
   @Input() evalMode: boolean;
   @Input() preliminary: boolean;
   @Input() isResultFilter: IResultFilter;
   @Output() filterName = new EventEmitter<{ dxcc: string; evalCategory: string }>();
   @Output() query = new EventEmitter<IFilter>();

   hideCardBody: boolean;
   resultsQuery = new FormGroup({
      'include-ha': new FormControl({ value: true, disabled: false }),
   });
   elemList: ElemList = [];

   dxccService: HttpService;
   dxccs: DXCCList[];
   loadDXCCSubject = new Subject<string>();
   loadDXCCObs = this.loadDXCCSubject.asObservable();

   evalCategoryService: HttpService;
   evals: EventEvals[];
   loadEvalCategorySubject = new Subject<string>();
   loadEvalCategoryObs = this.loadEvalCategorySubject.asObservable();

   queryParamsSubscription: Subscription;

   constructor(private http: HttpClient, private route: ActivatedRoute /*, private router: Router*/) {
      this.dxccService = new HttpService(this.http, api('nest', 'dxcc/readable'));
      this.evalCategoryService = new HttpService(this.http, api('nest', 'eval-category/event'));
   }

   ngOnInit() {
      forkJoin([
         this.dxccService.get<DXCCList[]>({}),
         this.evalCategoryService.get<EventEvals[]>({ eventId: this.event }),
      ]).subscribe((results) => {
         if (isResultValid(results[0])) {
            this.dxccs = [{ _id: 'all', country: translate('All') }].concat(results[0].body);
            if (!this.dxccs.map((d) => d._id).includes(this.isResultFilter.dxccs)) {
               this.dxccs = this.dxccs.concat({
                  _id: this.isResultFilter.dxccs,
                  country: translate(this.isResultFilter.name),
               });
            }
            this.queryParamsSubscription = this.route.queryParamMap.subscribe((queryParamMap) => {
               if (queryParamMap.has('dxcc')) {
                  this.loadDXCCSubject.next(queryParamMap.get('dxcc'));
                  this.hideCardBody = true;
               } else {
                  this.loadDXCCSubject.next('all');
               }
            });
         }
         if (isResultValid(results[1])) {
            this.evals = [{ _id: 'all', name: translate('All') }].concat(results[1].body);
            this.loadEvalCategorySubject.next('all');
         }
      });
   }

   ngOnDestroy() {
      this.queryParamsSubscription.unsubscribe();
   }

   onSubmit(dxcc?: string) {
      dxcc = dxcc || this.resultsQuery.get('dxcc').value;
      const evalCategory = this.resultsQuery.get('evals').value;
      this.query.next({
         dxcc,
         evalCategoryId: evalCategory,
         exclude: this.resultsQuery.get('include-ha').value ? '' : this.isResultFilter.dxccs,
      });
      setTimeout(() => {
         this.filterName.next({
            dxcc: this.dxccs.find((d) => d._id === dxcc).country,
            evalCategory: this.evals.find((d) => d._id === evalCategory).name,
         });
      }, 1000);
   }

   onHungarian() {
      this.resultsQuery.get('include-ha').setValue(true);
      this.loadDXCCSubject.next(this.isResultFilter.dxccs);
      this.onSubmit(this.isResultFilter.dxccs);
   }
}
