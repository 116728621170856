import { translate, TranslatePipe } from '@szegedsw/http.service';
import { bolder, center, Codes, Headers, right, translateHeaders } from '@szegedsw/table';
import { ClaimedQsosComponent as EvalClaimedQsos } from '../eval/claimed-qsos/claimed-qsos.component';
import { ClaimedQsosComponent as ResultsClaimedQsos } from '../results/claimed-qsos/claimed-qsos.component';
import { ReviewQsosComponent } from '../review-qsos/review-qsos.component';
import { qsosOnInit } from './qsos.common';

export function claimedQsosConstructor(claimedQsos: EvalClaimedQsos | ResultsClaimedQsos | ReviewQsosComponent) {
   claimedQsos.headers = new Headers([
      '#',
      'Date',
      'Time',
      right('Frequency'),
      center('Mode'),
      'Callsign',
      right('RST'),
      right('Sent'),
      right('RST'),
      right('Rcvd'),
      center('Status'),
      'Reason',
      center('Dupe'),
      right('Point'),
      center('Mult'),
   ]);
   claimedQsos.codes = new Codes([
      'index',
      'dateTimeDate',
      bolder('dateTimeTime'),
      right('freq'),
      center('mode'),
      bolder('callsign'),
      right('sRst'),
      right('sExch'),
      right('rRst'),
      right('rExch'),
      center('status'),
      'statusRsn',
      center('dupe'),
      right('points'),
      center('multis'),
   ]);
   claimedQsos.detailHeaders = new Headers([
      'Callsign',
      'Operators',
      'Round',
      right('QSO'),
      right('Point'),
      right('Mult'),
      right('Score'),
   ]);
   claimedQsos.detailCodes = new Codes([
      bolder('callsign'),
      bolder('upload.operators'),
      bolder('name'),
      right('qsos'),
      right('points'),
      right('multipliers'),
      right('score'),
   ]);
   translateClaimedQsos(claimedQsos);
   TranslatePipe.changed.subscribe(() => translateClaimedQsos(claimedQsos));
}

function translateClaimedQsos(claimedQsos: EvalClaimedQsos | ResultsClaimedQsos | ReviewQsosComponent) {
   translateHeaders(claimedQsos.headers, translate);
   translateHeaders(claimedQsos.detailHeaders, translate);
}

export function claimedQsosOnInit(claimedQsos: EvalClaimedQsos | ResultsClaimedQsos) {
   qsosOnInit(claimedQsos);
}
