import { translate } from '@szegedsw/http.service';
import { IconClass } from '@szegedsw/icon';
import { Locales } from '@szegedsw/modal';
import { IDropdown, NavbarHandler, translateButtons } from '@szegedsw/navbar';
import { uniqBy } from 'lodash';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../app.component';
import { Event } from '../entities/event.entity';

const classes = environment.production ? ['btn-primary'] : ['btn-danger'];

export const site = window.location.href.split('/')[2];

export const navHandler = new NavbarHandler({
   // text: environment.production ? "log.mrasz.hu" : "test.log.mrasz.hu",
   text: site,
   classes: environment.production ? ['bg-primary'] : ['bg-danger'],
});

export async function navLogin() {
   setNavButton(true);
   navAccount();
   if (AppComponent.isRight('SYSADMIN')) {
      navSysadmin();
   }
   if (AppComponent.isRight('ADMIN')) {
      navAdmin();
      navEval();
   }
}

export function navLogout() {
   setNavButton(false);
   navHandler.removeNav('account');
   navHandler.removeNav('sysadmin');
   navHandler.removeNav('admin');
   navHandler.removeNav('eval');
   navHandler.removeNav('eval-chooser');
}

export async function setNavButton(isLogged: boolean) {
   if (AppComponent.session) {
      navHandler.setButtons([
         isLogged ? { icon: new IconClass('fas:faSyncAlt', '', []), id: 'sync', text: '', classes } : undefined,
         isLogged
            ? {
                 id: isLogged ? 'logout' : 'login',
                 text: isLogged ? 'Logout' : 'Login',
                 classes: isLogged ? ['btn-secondary'] : ['btn-light'],
              }
            : undefined,
      ]);
      translateButtons(navHandler.buttons, translate);
   }
}

export async function navSubmit() {
   navHandler.addNav(
      {
         text: 'Current log submission',
         disabled: !(await AppComponent.getEvents()).some((event) => event.isSubmit),
         submenus: (await AppComponent.getEvents())
            .filter((event) => event.isSubmit)
            .map<IDropdown>((value) => {
               return { text: value.name, link: ['submit', value._id] };
            }),
         classes,
      },
      0,
   );
}

export async function navOnlineLogger() {
   navHandler.addNav(
      {
         text: 'Online logger',
         disabled: !(await AppComponent.getEvents()).some((event) => event.isSubmit && event.isOnlineLogger),
         submenus: (await AppComponent.getEvents())
            .filter((event) => event.isSubmit && event.isOnlineLogger)
            .map<IDropdown>((value) => {
               return { text: value.name, link: ['logger', value._id] };
            }),
         classes,
      },
      1,
   );
}

export async function navResult() {
   const events = await AppComponent.getEvents();
   const submenus = uniqBy(
      events.filter((event) => isResult(event)),
      'contest._id',
   ).map<IDropdown>((unique) => {
      return { text: unique.contest.name, submenus: resultSubmenus(events, unique.contest._id) };
   });
   submenus.sort((a, b) => {
      return a.text > b.text ? 1 : -1;
   });
   navHandler.addNav(
      {
         text: 'Results',
         disabled: !events.some((event) => isResult(event)),
         submenus: [{ text: 'Error code meaning', link: ['/results/error-codes'] }, ...submenus],
         classes,
      },
      2,
   );
   navHandler.addNav(
      {
         text: 'Awards',
         link: ['award'],
         classes,
      },
      5,
   );
}

function isResult(event: Event) {
   return event.isClaimed || event.isPreliminary || event.isFinal;
}

function resultSubmenus(events: Event[], contestId: string): IDropdown[] {
   events.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
   });
   const eventMenu = (event: Event) => [
      event.isClaimed ? { text: 'Claimed results', link: ['results', event._id, 'claimed'] } : undefined,
      event.isSubmittedLogs ? { text: 'Submitted logs', link: ['results', event._id, 'logs', 'claimed'] } : undefined,
      event.isSoapbox ? { text: 'Soapbox', link: ['results', event._id, 'soapbox'] } : undefined,
      event.isOperators ? { text: 'Operators', link: ['results', event._id, 'operators'] } : undefined,
      event.isPreliminary || event.isFinal
         ? { text: event.isFinal ? 'Final results' : 'Preliminary results', link: ['results', event._id, 'preliminary'] }
         : undefined,
      event.isPreliminary || event.isFinal
         ? { text: 'Checked logs', link: ['results', event._id, 'logs', 'preliminary'] }
         : undefined,
   ];
   const contests = events.filter((event) => event.contest._id === contestId);
   const submenus: IDropdown[] =
      contests.length > 1
         ? contests.map<IDropdown>((event) => {
              return { text: event.name, submenus: eventMenu(event) };
           })
         : eventMenu(contests[0]);
   return submenus;
}

export async function navRules() {
   const events = await AppComponent.getEvents();
   const submenus = uniqBy(
      events.filter((event) => isRules(event)),
      'contest._id',
   ).map<IDropdown>((unique) => {
      return { text: unique.contest.name, submenus: rulesSubmenus(events, unique.contest._id) };
   });
   submenus.sort((a, b) => {
      return a.text > b.text ? 1 : -1;
   });
   navHandler.addNav(
      {
         text: 'Rules & files',
         disabled: !events.some((event) => isResult(event)),
         submenus: submenus,
         classes,
      },
      2,
   );
}

function isRules(event: Event) {
   return event.uploadedFileNames.length > 0 || event.rules;
}

function rulesSubmenus(events: Event[], contestId: string): IDropdown[] {
   events.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
   });
   const contests = events.filter((event) => event.contest._id === contestId);
   const submenus: IDropdown[] =
      contests.length > 1
         ? contests.map<IDropdown>((event) => {
              return isRules(event) ? { text: event.name, submenus: rulesEventMenu(event) } : undefined;
           })
         : rulesEventMenu(contests[0]);
   return submenus;
}

function rulesEventMenu(event: Event) {
   const menus = event.rules ? [{ text: 'Rules', onclick: true, id: 'link-' + event.rules }] : [];
   event.uploadedFileNames.forEach((f) => {
      menus.push({ text: f.slice(25), onclick: true, id: 'file-' + f });
   });
   return menus;
}

export async function navStatistics() {
   const events = await AppComponent.getEvents();
   const submenus = uniqBy(
      events.filter((event) => isStats(event)),
      'contest._id',
   ).map<IDropdown>((unique) => {
      return { text: unique.contest.name, submenus: statsSubmenus(events, unique.contest._id) };
   });
   submenus.sort((a, b) => {
      return a.text > b.text ? 1 : -1;
   });
   navHandler.addNav(
      {
         text: 'Statistics',
         disabled: !events.some((event) => isStats(event)),
         submenus: submenus,
         classes,
      },
      3,
   );
}

function isStats(event: Event) {
   return event.isDailyUploaded;
}

function statsSubmenus(events: Event[], contestId: string): IDropdown[] {
   const eventMenu = (event: Event) => [
      event.isDailyUploaded ? { text: 'Daily uploaded logs', link: ['stats', event._id, 'logs-count'] } : undefined,
      event.isSumOfTotalScoresPerRegion
         ? { text: 'Sum of total score per region', link: ['stats', event._id, 'score-region'] }
         : undefined,
      event.isSumOfTotalScoresPerCountry
         ? { text: 'Sum of total score per country', link: ['stats', event._id, 'score-country'] }
         : undefined,
      event.isSumOfTotalScoresPerCountryPerRound
         ? { text: 'Sum of total score per country per round', link: ['stats', event._id, 'score-country-round'] }
         : undefined,
      event.isSubmittedList ? { text: 'Submitted list of logs', link: ['stats', event._id, 'submitted-list'] } : undefined,
   ];
   const contests = events.filter((event) => event.contest._id === contestId);
   const submenus: IDropdown[] =
      contests.length > 1
         ? contests.map<IDropdown>((event) => {
              return { text: event.name, submenus: eventMenu(event) };
           })
         : eventMenu(contests[0]);
   return submenus;
}

export async function navCallbook() {
   if (AppComponent.session && AppComponent.session.defaultSites.includes(site)) {
      navHandler.addNav(
         {
            text: 'Callbook',
            link: ['callbook'],
            classes,
         },
         3,
      );
      navHandler.addNav(
         {
            text: 'QSL members',
            link: ['qsl-member'],
            classes,
         },
         5,
      );
   }
}

function navAccount() {
   navHandler.addNav({
      text: 'My account',
      id: 'account',
      submenus: [
         // { text: 'Jegyzőkönyvek', link: ['/logs'], disabled: true },
         // { text: 'Beállítások', link: ['/settings'], disabled: true },
         { text: 'Password change', link: ['change-pwd'] },
         {
            icon: new IconClass('fas:faGlobeAmericas', 'language', [], ''),
            text: 'Language',
            submenus: [
               { text: 'English', id: Locales.en, onclick: true },
               { text: 'Magyar', id: Locales.hu, onclick: true },
            ],
         },
      ],
      classes,
   });
}

function navSysadmin() {
   navHandler.addNav({
      text: 'System',
      id: 'sysadmin',
      submenus: [
         { text: 'Users', link: ['/sysadmin/users'] },
         { text: 'Contests', link: ['/sysadmin/contests'] },
         { text: 'Category types', link: ['/sysadmin/category-types'] },
         { text: 'DXCC list', link: ['/sysadmin/dxcc'] },
         { text: 'Translations', link: ['/sysadmin/translations'] },
         { text: 'Bull Dashboard', onclick: true, id: 'bull-dashboard' },
         // { text: 'Munkamenetek', link: ['/sessions'], disabled: true },
         // { text: 'Audit log', link: ['/audit'], disabled: true }
      ],
      classes,
   });
}

function navAdmin() {
   navHandler.addNav({
      text: 'Admin',
      id: 'admin',
      submenus: [
         { text: 'Categories', link: ['/admin/categories'] },
         { text: 'Rounds', link: ['/admin/rounds'] },
         { text: 'Evaluation categories', link: ['/admin/eval-categories'] },
         { text: 'Events', link: ['/admin/events'] },
         // { text: 'Pontozás', link: ['/scoring'], disabled: true },
      ],
      classes,
   });
}

function navEval() {
   navHandler.addNav({
      text: 'Evaluation',
      id: 'eval',
      link: ['eval', 'chooser'],
      classes,
   });
}
