import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
   HttpLoadingInterceptorService,
   HttpNoCacheInterceptorService,
   HttpResponseInterceptorService,
   HttpVersionInterceptorService,
} from '@szegedsw/http.service';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { version } from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AwardComponent } from './award/award.component';
import { CallbookComponent } from './callbook/callbook.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OfflineQsoFormComponent } from './common/offline-qso-form/offline-qso-form.component';
import { QsoCardComponent } from './common/qso-card/qso-card.component';
import { QsoFormComponent } from './common/qso-form/qso-form.component';
import { QsoSettingsComponent } from './common/qso-settings/qso-settings.component';
import { CallsignDirective } from './directives/callsign.directive';
import { HomeComponent } from './home/home.component';
import { LoggerComponent } from './logger/logger.component';
import { LoginComponent } from './login/login.component';
import { MainModule } from './main/main.module';
import { NavComponent } from './nav/nav.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { QslMemberComponent } from './qsl-member/qsl-member.component';
import { QsoTyperComponent } from './qso-typer/qso-typer.component';
import { ReviewQsosComponent } from './review-qsos/review-qsos.component';

@NgModule({
   declarations: [
      AppComponent,
      NavComponent,
      NotFoundComponent,
      HomeComponent,
      CallbookComponent,
      ReviewQsosComponent,
      QslMemberComponent,
      AwardComponent,
      LoginComponent,
      ChangePasswordComponent,
      QsoTyperComponent,
      LoggerComponent,
      CallsignDirective,
      QsoCardComponent,
      QsoSettingsComponent,
      QsoFormComponent,
      OfflineQsoFormComponent,
   ],
   imports: [
      BrowserAnimationsModule,
      HttpClientModule,
      NgxSpinnerModule,
      AppRoutingModule,
      MainModule,
      DatepickerModule,
      ToastrModule.forRoot(),
      NgcCookieConsentModule.forRoot({
         cookie: {
            domain: window.location.hostname,
            secure: environment.cookie.secure,
         },
         position: 'top-right',
         palette: {
            popup: {
               background: '#f8f9fa',
               text: '#000000',
            },
            button: {
               background: environment.cookie.button,
               text: '#ffffff',
            },
         },
         theme: 'classic',
         type: 'info',
         // dismissOnTimeout: 8000,
         content: {
            message: 'This website uses cookies to ensure you get the best experience on our website.',
            dismiss: 'Got it!',
            link: 'Learn more',
            href: '/api/assets/GDPR-Adatkezelési-Tájékoztató-log.mrasz.hu.pdf',
         },
         autoOpen: false,
      }),
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useFactory: () => new HttpLoadingInterceptorService(AppComponent.spinner),
         multi: true,
      },
      {
         provide: HTTP_INTERCEPTORS,
         useFactory: () => new HttpNoCacheInterceptorService(),
         multi: true,
      },
      {
         provide: HTTP_INTERCEPTORS,
         useFactory: () =>
            new HttpResponseInterceptorService(
               AppComponent.isRight,
               AppComponent.timeoutHandler,
               AppComponent.onUnauthorized,
               AppComponent.internalErrorHandler,
               AppComponent.errorHandler,
               ['/nest/submit/claim', '/nest/submit/upload', 'nest/qso/online-logger'],
               AppComponent.reloadHandler,
            ),
         multi: true,
      },
      {
         provide: HTTP_INTERCEPTORS,
         useFactory: () => new HttpVersionInterceptorService(version),
         multi: true,
      },
      DatePipe,
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
