import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AppComponent } from '../app.component';

@Injectable({
   providedIn: 'root',
})
export class CanActivateEvalService implements CanActivateChild {
   constructor(private router: Router) {}

   canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (AppComponent.isRight('EVAL')) {
         return true;
      }
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
      return false;
   }
}
