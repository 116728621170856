/* eslint-disable no-shadow */
export enum CallBooks {
   partnerCode = 'partnerCode',
   nameOfLicensee = 'nameOfLicensee',
   country = 'country',
   zip = 'zip',
   city = 'city',
   streetHouse = 'streetHouse',
   licenseNumber = 'licenseNumber',
   callSign = 'callSign',
   communityOrPrivate = 'communityOrPrivate',
   stateOfLicence = 'stateOfLicense',
   levelOfExam = 'levelOfExam',
   morse = 'morse',
   licenseDate = 'licenseDate',
   validity = 'validity',
   chiefOperator = 'chiefOperator',
   expiringMonths = 'expiringMonths',
}

export class CallBook {
   public [CallBooks.partnerCode]: string;

   public [CallBooks.nameOfLicensee]: string;

   public [CallBooks.country]: string;

   public [CallBooks.zip]: string;

   public [CallBooks.city]: string;

   public [CallBooks.streetHouse]: string;

   public [CallBooks.licenseNumber]: string;

   public [CallBooks.callSign]: string;

   public [CallBooks.communityOrPrivate]: boolean;

   public [CallBooks.stateOfLicence]: string;

   public [CallBooks.levelOfExam]: string;

   public [CallBooks.morse]: boolean;

   public [CallBooks.licenseDate]: Date;

   public [CallBooks.validity]: Date;

   public [CallBooks.chiefOperator]: string;

   public [CallBooks.expiringMonths]: number;
}
