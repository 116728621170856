import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppComponent } from '../app.component';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.css'],
})
export class LoginComponent {
   loginForm = new FormGroup({
      username: new FormControl(''),
      password: new FormControl(''),
   });

   onLogin() {
      const { username, password } = this.loginForm.value as any;
      AppComponent.loginEvent.next({ username, password });
   }
}
