import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Codes, Headers, IField, IHeader } from '@szegedsw/table';
import { callsignRegExp } from '../../config/config';
import { Qso } from '../../qso-typer/qso-typer.component';
@Component({
   selector: 'app-qso-settings',
   templateUrl: './qso-settings.component.html',
   styleUrls: ['./qso-settings.component.css'],
})
export class QsoSettingsComponent implements OnInit {
   @Input('qsoForm') qsoForm: FormGroup;
   @Output('qsoForm') qsoFormChange = new EventEmitter<FormGroup>();
   @Input('qsos') qsos: Qso[];
   @Input('codes') codes: Codes;
   @Output('codes') codesChange = new EventEmitter<Codes>();
   @Input('headers') headers: Headers;
   @Output('headers') headersChange = new EventEmitter<Headers>();
   @Output('loadEvents') loadEvents = new EventEmitter();
   @Input('fieldsToShow') fieldsToShow;
   @Input('isOnlineChosenExchange') isOnlineChosenExchange: boolean;
   @Input('isSentNumber') isSentNumber: boolean;
   @Input('isSentString') isSentString: boolean;
   @Output('changeSettings') changeSettings = new EventEmitter<any>();

   qsoSettingsForm = new FormGroup({
      autoTime: new FormControl(true),
      autoNum: new FormControl(),
      copyExchange: new FormControl(true),
      copyNumber: new FormControl(),
      isFreq: new FormControl(),
      isXQso: new FormControl(),
   });

   autoTime = false;
   autoNum = true;
   copyExchange = true;
   copyNumber = false;
   isFreq = false;
   isXQso = false;

   hideSearchBody = false;

   constructor() {
      this.qsoForm = new FormGroup({
         date: new FormControl('', Validators.required),
         time: new FormControl('', Validators.required),
         freq: new FormControl(''),
         call: new FormControl('', [Validators.required, Validators.pattern(callsignRegExp)]),
         sRst: new FormControl('', Validators.required),
         sNum: new FormControl(),
         sExch: new FormControl(),
         sWwl: new FormControl(),
         rRst: new FormControl('', Validators.required),
         rNum: new FormControl(),
         rExch: new FormControl(),
         rWwl: new FormControl(),
      });
   }

   ngOnInit() {
      if (localStorage.getItem('qsoFormSettings')) {
         this.qsoSettingsForm.setValue(JSON.parse(localStorage.getItem('qsoFormSettings')));
         this.onApply();
         this.onDisable();
      }
      setTimeout(() => {
         this.onApply();
         if (this.isSentNumber) {
            this.qsoSettingsForm.get('copyNumber').setValue(true);
            this.qsoSettingsForm.get('copyNumber').disable();
            this.qsoSettingsForm.get('autoNum').disable();
         }
         if (this.isSentString) {
            this.qsoSettingsForm.get('copyExchange').setValue(true);
            this.qsoSettingsForm.get('copyExchange').disable();
         }
      }, 200);
   }

   onApply(type?: string) {
      if (this.qsoForm.getRawValue().time) {
         localStorage.setItem('qsoFormSettings', JSON.stringify(this.qsoSettingsForm.getRawValue()));
         this.autoTime = !!this.qsoSettingsForm.get('autoTime').value;
         this.autoNum = !!this.qsoSettingsForm.get('autoNum').value;
         this.copyExchange = !!this.qsoSettingsForm.get('copyExchange').value;
         this.copyNumber = !!this.qsoSettingsForm.get('copyNumber').value;
         this.isFreq = !!this.qsoSettingsForm.get('isFreq').value;
         this.isXQso = !!this.qsoSettingsForm.get('isXQso').value;
         if (this.autoNum) {
            if (this.qsos.length === 0) {
               this.qsoForm.get('sNum').setValue(1);
            } else {
               this.qsoForm.get('sNum').setValue(this.qsos[this.qsos.length - 1].sNum + 1);
            }
         }
         if (type === 'realTime' && !this.autoTime) {
            this.qsoForm.get('date').setValue(undefined);
            this.qsoForm.get('time').setValue(undefined);
         }
         if (this.isFreq && this.codes) {
            if (this.codes.fields[4].str !== 'freq') {
               this.headers.rows[0].splice(4, 0, { str: 'Freq.', rowspan: 2 } as unknown as IHeader);
               this.codes.fields.splice(4, 0, { str: 'freq' } as IField);
            }
         } else if (this.codes && this.codes.fields[4].str === 'freq') {
            this.headers.rows[0].splice(4, 1);
            this.codes.fields.splice(4, 1);
         }
         if (!this.isXQso && this.codes && this.codes.fields[this.codes.fields.length - 3].str === 'xQso') {
            this.codes.fields.splice(this.codes.fields.length - 3, 1);
            this.headers.rows[0].splice(this.headers.rows[0].length - 3, 1);
         }
         if (this.isXQso && this.codes && this.codes.fields[this.codes.fields.length - 3].str !== 'xQso') {
            this.codes.fields.splice(this.codes.fields.length - 2, 0, { str: 'xQso' });
            this.headers.rows[0].splice(this.headers.rows[0].length - 2, 0, { str: 'X-QSO', rowspan: 2 });
         }
         this.qsoFormChange.emit(this.qsoForm);
         this.headersChange.emit(this.headers);
         this.codesChange.emit(this.codes);
         this.changeSettings.emit({
            autoTime: this.autoTime,
            autoNum: this.autoNum,
            copyExchange: this.copyExchange,
            copyNumber: this.copyNumber,
            isFreq: this.isFreq,
            isXQso: this.isXQso,
         });
      }
   }

   onDisable() {
      this.qsoSettingsForm.get('copyNumber').enable();
      this.qsoSettingsForm.get('autoNum').enable();
      setTimeout(() => {
         if (this.qsoSettingsForm.get('autoNum').value) {
            this.qsoSettingsForm.get('copyNumber').disable();
         }
         if (this.qsoSettingsForm.get('copyNumber').value) {
            this.qsoSettingsForm.get('autoNum').disable();
         }
      }, 50);
   }

   changeHide() {
      this.hideSearchBody = !this.hideSearchBody;
      if (!this.hideSearchBody) {
         setTimeout(() => {
            this.loadEvents.emit();
         }, 100);
      }
   }
}
