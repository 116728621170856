import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService, translate, TranslatePipe } from '@szegedsw/http.service';
import { IconClass } from '@szegedsw/icon';
import { center, Codes, Headers, right, splitDateTimeToLocale } from '@szegedsw/table';
import { flatten } from 'lodash';
import { claimedQsosConstructor } from '../common/claimed-qsos.common';
import { api } from '../config/config';
import { IScore } from '../entities/claimed.entity';
import { Log, Logs } from '../entities/log.entity';
import { Qso, Qsos as QsosEnum } from '../entities/qso.entity';
import { Round } from '../entities/round.entity';

@Component({
   selector: 'app-review-qsos',
   templateUrl: './review-qsos.component.html',
   styleUrls: ['./review-qsos.component.css'],
})
export class ReviewQsosComponent implements OnInit {
   rows: Qso[] = [];

   headers: Headers;
   codes: Codes;

   details = [];
   detailHeaders: Headers;
   detailCodes: Codes;

   service: HttpService;
   queryParams: Params = {};
   event: string;

   isAdmin = false;

   constructor(private http: HttpClient, public route: ActivatedRoute, public router: Router) {
      this.isAdmin = this.route.snapshot.url.some((u) => u.path === 'admin');
      this.service = new HttpService(this.http, api('nest', 'submit/review-qsos'));
      this.queryParams = this.route.snapshot.queryParams;
      claimedQsosConstructor(this);
      this.headers.rows[0].push(center('Possible format errors'));
      this.codes.fields.push(center('validation'));
      this.detailHeaders.rows[0].push(right('Possible format errors'));
      this.detailCodes.fields.push(right('validation'));
   }

   ngOnInit() {
      const upload = this.route.snapshot.paramMap.get('upload');
      this.service.get<Array<{ round: Round; log: Log; qsos: Qso[]; score: IScore }>>({ uploadId: upload }).subscribe((res) => {
         this.rows = flatten(res.body.map((b) => b.qsos));

         this.details = res.body.map((details) => ({
            ...details.log,
            ...details.round,
            ...details.score,
            validation: this.rows.filter((r) => (r.validation as any[]).length).length,
         }));

         this.rows.forEach((row, index) => {
            row.rExch = (row.rNum ? String(row.rNum) : '') + ' ' + (row.rExch ? row.rExch : '');
            row.sExch = (row.sNum ? String(row.sNum) : '') + ' ' + (row.sExch ? row.sExch : '');
            row.index = `${index + 1}.`;

            if (!(row.validation instanceof IconClass)) {
               row.warning = !!row.validation.length;

               if (row.warning) {
                  row.validation = new IconClass('fas:faInfoCircle', '', [], row.validation.map((v) => v.property).join('\n'));
               } else {
                  delete row.validation;
               }
            }
         });
         splitDateTimeToLocale(this.rows, [QsosEnum.dateTime], TranslatePipe.getLocale(), [
            { timeZone: 'UTC' },
            { timeZone: 'UTC', hour: '2-digit', minute: '2-digit' },
         ]);
         this.rows.forEach((r) => (r.multis = r.multis.join(', ') as any));

         if (!this.rows.some((row) => row.freq) && this.rows.some((row) => row.band)) {
            this.headers.rows[0].splice(3, 1, right(translate('Band')));
            this.codes.fields.splice(3, 1, right('band'));
         }
         if (
            this.rows.some((row) => row.rWWL && row.rWWL.length > 0) &&
            this.codes.fields.findIndex((field) => field.str === 'rWWL')
         ) {
            this.headers.rows[0].splice(10, 0, center(translate('Locator')));
            this.codes.fields.splice(10, 0, center('rWWL'));
         }

         if (
            Object.keys(this.details[0]).includes('WWL') &&
            this.detailCodes.fields.findIndex((field) => field.str === 'WWL') < 0
         ) {
            this.detailHeaders.rows[0].splice(1, 0, { str: translate('Locator') });
            this.detailCodes.fields.splice(1, 0, { str: 'WWL' });
         }

         if (res.body.some(({ log }) => log.evalCategories.some(({ isSWL }) => isSWL))) {
            this.headers.rows[0].splice(8, 0, center(translate('Heard callsign')));
            this.codes.fields.splice(8, 0, center('heardCallsign'));
         }

         this.event = String(res.body[0].log[Logs.event]);
      });
   }

   onHeaderEvent() {
      if (this.isAdmin) {
         this.router.navigate(['eval', this.event, 'admin-submit'], { queryParams: this.queryParams });
      } else {
         this.router.navigate(['/submit', this.event], { queryParams: this.queryParams });
      }
   }
}
