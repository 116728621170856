import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService, translate } from '@szegedsw/http.service';
import { Codes, Headers } from '@szegedsw/table';
import { claimedQsosConstructor, claimedQsosOnInit } from 'src/app/common/claimed-qsos.common';
import { api } from 'src/app/config/config';
import { Qso } from 'src/app/entities/qso.entity';

@Component({
   selector: 'app-claimed-qsos',
   templateUrl: './claimed-qsos.component.html',
   styleUrls: ['./claimed-qsos.component.css'],
})
export class ClaimedQsosComponent implements OnInit {
   rows: Qso[] = [];

   headers: Headers;
   codes: Codes;

   details = [];
   detailHeaders: Headers;
   detailCodes: Codes;

   service: HttpService;
   params: Map<string, string> = new Map();
   queryParams: Params = {};

   translate = translate;

   constructor(private http: HttpClient, public route: ActivatedRoute, public router: Router) {
      this.service = new HttpService(this.http, api('nest', 'qso'));
      claimedQsosConstructor(this);
   }

   ngOnInit() {
      claimedQsosOnInit(this);
   }

   onHeaderEvent() {
      this.router.navigate(['/eval', this.params.get('event'), 'logs', 'claimed'], { queryParams: this.queryParams });
   }
}
