import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '@szegedsw/http.service';
import { ElemList } from '@szegedsw/select';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { api } from '../config/config';
import { Event } from '../entities/event.entity';

@Component({
   selector: 'app-award',
   templateUrl: './award.component.html',
   styleUrls: ['./award.component.css'],
})
export class AwardComponent implements OnInit {
   elemList: ElemList = [];
   loadEventsCategorySubject = new Subject<string>();
   loadEventsCategoryObs = this.loadEventsCategorySubject.asObservable();

   awardForm = new FormGroup({
      callsign: new FormControl(''),
   });

   events: Event[] = [];

   queryEvent: Event;

   evalCategoriesService: HttpService;

   evalCategories = [];
   queried = false;

   constructor(private route: ActivatedRoute, private http: HttpClient) {
      this.evalCategoriesService = new HttpService(this.http, api('nest', 'log/eval-categories'));
   }

   async ngOnInit() {
      await AppComponent.getEvents().then((events) => {
         this.events = events
            .filter((e) => e.award && e.award.isAvailable && e.isFinal)
            .map((e) => {
               return { ...e, name: `${e.name} - ${e.year}` };
            });
         this.loadEventsCategorySubject.next();
      });
      this.route.queryParamMap.subscribe((q) => {
         if (q.get('event')) {
            const i = this.events.findIndex((e) => e._id === q.get('event'));
            if (i > -1) {
               this.queryEvent = this.events[i];
            }
         }
         if (q.get('callsign')) {
            this.awardForm.get('callsign').setValue(q.get('callsign'));
            this.awardForm.get('callsign').disable();
         }
      });
   }

   onQuery() {
      this.evalCategoriesService.get<{ evalCategories: any[] }>(
         { event: this.queryEvent ? this.queryEvent._id : undefined, ...this.awardForm.value },
         (res) => {
            this.evalCategories = res.body ? res.body.evalCategories : [];
            this.queried = true;
         },
      );
   }

   onReset() {
      this.evalCategories = [];
      this.awardForm.get('callsign').reset();
      this.queried = false;
   }

   getQueryParams(evalCategory: string) {
      return new URLSearchParams({
         event: this.queryEvent ? this.queryEvent._id : undefined,
         ...this.awardForm.value,
         evalCategory,
      }).toString();
   }
}
