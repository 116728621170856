import { Directive, ElementRef, HostListener } from '@angular/core';
import { callsignRegExp } from '../config/config';

@Directive({
   selector: '[callsign]',
})
export class CallsignDirective {
   regex = new RegExp(/^(([A-Z,0-9,\/])?$)/);
   private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'Space'];
   constructor(private el: ElementRef) {}

   @HostListener('keydown', ['$event'])
   onKeyDown(event: KeyboardEvent) {
      if (this.specialKeys.indexOf(event.key) !== -1) {
         return;
      }

      const current: string = this.el.nativeElement.value;
      const next: string = current.concat(event.key);
      if (next && !this.regex.test(event.key.toUpperCase())) {
         event.preventDefault();
      }
   }
}
