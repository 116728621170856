import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
   enableProdMode();
}

platformBrowserDynamic()
   .bootstrapModule(AppModule)
   .then((ref) => {
      // Ensure Angular destroys itself on hot reloads.
      // tslint:disable: no-string-literal
      if (window['ngRef']) {
         window['ngRef'].destroy();
      }
      window['ngRef'] = ref;

      // Otherwise, log the boot error
   })
   // eslint-disable-next-line no-console
   .catch((err) => console.error(err));
