/* eslint-disable no-shadow */
import { Category } from './category.entity';
import { DXCC } from './dxcc.entity';
import { EvalCategory } from './eval.category.entity';
import { Event } from './event.entity';
import { File } from './file.entity';
import { Round } from './round.entity';
import { Upload } from './upload.entity';

export enum Logs {
   _id = '_id',
   file = 'file',
   logStatus = 'logStatus',
   callsign = 'callsign',
   event = 'event',
   categories = 'categories',
   round = 'round',
   upload = 'upload',
   headers = 'headers',
   remarks = 'remarks',
   WWL = 'WWL',
   format = 'format',
   dxcc = 'dxcc',
   evalCategories = 'evalCategories',
   comment = 'comment',
   createdAt = 'createdAt',
   updatedAt = 'updatedAt',
}

export class Log {
   public [Logs.file]!: File;

   public [Logs.logStatus]!: string;

   public [Logs.callsign]?: string;

   public [Logs.event]?: Event;

   public [Logs.categories]?: Category[];

   public [Logs.round]?: Round;

   public [Logs.upload]?: Upload;

   public [Logs.headers]?: string[];

   public [Logs.remarks]?: string[];

   public [Logs.WWL]?: string;

   public [Logs.format]?: string;

   public [Logs.dxcc]?: DXCC;

   public [Logs.evalCategories]?: EvalCategory[];

   public [Logs.comment]?: string;

   public [Logs._id]!: string;

   public [Logs.createdAt]!: Date;

   public [Logs.updatedAt]!: Date;
}

export interface IGetPreliminary {
   callsign: string;
   WWL: string;
   qsos: number;
   points: number;
   multipliers: number;
   score: number;
   code: string;
}

export interface IGetEval {
   _id: string;
   WWL: string;
   callsign: string;
   claimedScore: number;
   createdAt: Date;
   multipliers: number;
   points: number;
   qsos: number;
   round: Round;
   status: string;
}

export interface ILogsCount {
   date: Date;
   count: number;
}

export interface ISubmittedList {
   callsign: string;
   createdAt: Date;
}

export interface ICallsignList {
   callsign: string;
   categories: Category[];
   count: number;
   sent: boolean;
   log: string;
}

export interface IReverseLogs {
   _id: { callsign: string; event: string };
   reversed: boolean;
   sum: number;
}
