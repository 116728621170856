import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpService, isResultValid } from '@szegedsw/http.service';
import { api } from '../config/config';

@Component({
   selector: 'app-change-password',
   templateUrl: './change-password.component.html',
   styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent {
   changePwForm: FormGroup;
   sessionService: HttpService;

   success = false;

   constructor(private http: HttpClient) {
      this.sessionService = new HttpService(this.http, api('nest', 'session'));
      this.changePwForm = new FormGroup({
         oldPassword: new FormControl(),
         newPassword1: new FormControl(),
         newPassword2: new FormControl(),
      });
   }

   onChangePassword() {
      this.success = false;
      const old = this.changePwForm.get('oldPassword').value;
      const new1 = this.changePwForm.get('newPassword1').value;
      const new2 = this.changePwForm.get('newPassword2').value;
      if (new1 === new2 && old !== new1) {
         this.sessionService.patch('', { oldPassword: old, newPassword: new1 }, (res) => {
            if (isResultValid(res)) {
               this.changePwForm.reset();
               this.success = true;
            }
         });
      }
   }
}
